import React, { useState } from 'react';
import { ThemeProvider, createTheme, CssBaseline, AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import AppContent from './AppContent';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            ALL HAIL JUSTIN!
          </Typography>
          <Button color="inherit" onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? <LightMode /> : <DarkMode />}
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <br />
        <br />

        <AppContent />
      </Container>
    </ThemeProvider>
  );
}

export default App;
